import React, { Fragment, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// functions
import getLocalizeText from 'utils/getLocalizeText';
import { formatCurrency, getGender, ProfileRootState, propertyOptions } from 'utils';
import * as objectFunctions from '../utils/objectFunctions';

import englishText from 'languages/english';
import * as objectDescInterface from '../interface/objectDetails';
// Assets
import { DetailsIcon, FinancesIcon, MapPinIcon, ObjectDataIcon } from 'components/commonComponents/icons';
import { propertyOption } from 'components/newMyProperty/utils/constants';

const ObjectDescription = (props: objectDescInterface.IObjectDescProps) => {
	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);
	const { state, setStateForObject } = props;
	const { propertyDetail, objectDetails, showReadMore } = state;
	const token = localStorage.getItem('token');
	const detailsArray = propertyDetail && objectDetails && objectFunctions.getDetail(propertyDetail, objectDetails);

	const factsArray = propertyDetail && objectDetails && objectFunctions.getFacts(propertyDetail, objectDetails);

	const financeArray =
		propertyDetail && (propertyDetail.marketingType || propertyDetail.demandType) !== propertyOptions.RENT
			? objectFunctions.getFinance(propertyDetail)
			: objectFunctions.getRentFinance(propertyDetail);

	const descArray =
		propertyDetail &&
		propertyDetail.objectDescription &&
		objectFunctions.getDescription(propertyDetail, showReadMore);

	const isRent = useMemo(
		() => (propertyDetail.marketingType || propertyDetail.demandType) === propertyOptions.RENT,
		[propertyDetail.marketingType, propertyDetail.demandType]
	);

	const isRealtor = useMemo(() => !!propertyDetail.realtor, [propertyDetail.realtor]);
	const user = useMemo(() => {
		if (!propertyDetail) {
			return null;
		}
		if (isRealtor) {
			return {
				name: propertyDetail.realtor?.contact_person_name || '',
				phone: propertyDetail.realtor?.phone || `+${propertyDetail.user?.phone || ''}` || ''
			};
		} else {
			return {
				name: `${propertyDetail.user?.gender ? getGender(propertyDetail.user.gender) : ''} ${
					propertyDetail.user?.firstName || ''
				} ${propertyDetail.user?.lastName || ''}`,
				phone: propertyDetail.user?.phone ? `+${propertyDetail.user?.phone || ''}` : ''
			};
		}
	}, [propertyDetail, isRealtor]);

	return (
		<>
			<div className='object-description'>
				<div className='row'>
					<div className='col-lg-8 col-md-7 col-6'>
						<div className='column-wrapper'>
							<div className='d-flex justify-content-between align-items-center'>
								<div>
									<h2 className='title'> {propertyDetail.objectTitle}</h2>
									{propertyDetail.platforms && propertyDetail.platforms.length > 0 && (
										<div className='m-t-15 m-b-15'>
											<p className='font-size--16'>
												Immo-ID: <b>{propertyDetail.platforms[0].platform_id || '-'}</b>
											</p>
										</div>
									)}
									<div className='d-flex justify-content-start align-items-center'>
										<div>
											<MapPinIcon className='map-icon' />
											<span
												className='pointer'
												onClick={() => {
													setStateForObject('showMapPopup', true);
												}}
												style={{ textDecoration: 'underline' }}
											>
												{getLocalizeText(englishText.SHOW_ON_MAP)}
											</span>
										</div>
									</div>
								</div>
								<p className='object-status'>
									{propertyDetail.isNewProperty && <span>{getLocalizeText(englishText.NEW)}</span>}
									{propertyDetail.dayDiffrence}
								</p>
							</div>

							{propertyDetail.objectDescription && (
								<>
									<h2 className='title mt-4 mb-4'>{getLocalizeText(englishText.OBJ_DESC)}</h2>
									<div className='object-para'>
										{descArray.map((text: string, i: number) => (
											<Fragment key={i}>
												<p dangerouslySetInnerHTML={{ __html: `${text} <br />` || '' }} />
												{i === descArray.length - 1 &&
													!showReadMore &&
													propertyDetail.objectDescription.length >
														objectFunctions.charLimit &&
													'...'}
											</Fragment>
										))}
									</div>
									{propertyDetail.objectDescription.length > objectFunctions.charLimit && (
										<p
											className='read-more'
											onClick={() => {
												if (showReadMore) {
													window.scrollTo(0, 200);
												}
												setStateForObject('showReadMore', !showReadMore);
											}}
										>
											{showReadMore
												? getLocalizeText(englishText.SHOW_LESS)
												: getLocalizeText(englishText.READ_MORE)}
										</p>
									)}
								</>
							)}
						</div>
					</div>
					<div className='col-lg-4 col-md-5 col-6'>
						<div className='description-card'>
							<div className='card-item'>
								<div className='d-flex'>
									<h2 className='card-title'>
										{propertyDetail.propertyType
											? `${getLocalizeText(propertyDetail.propertyType)} ${
													propertyDetail.adrPostalCode &&
													' in ' + propertyDetail.adrPostalCode
											  } 
                      ${(propertyDetail.adrQuarter && ', ' + propertyDetail.adrQuarter) || propertyDetail.city}`
											: `${propertyDetail.adrStreet || '-'}`}
									</h2>
								</div>
								<div className='d-flex align-items-end mt-3' style={{ placeContent: 'space-between' }}>
									<div className='object-items'>
										{!isRent && propertyDetail.pricePerSqm && (
											<>
												<h2>{propertyDetail.pricePerSqm || '-'}</h2>
												<h6>
													<Trans
														i18nKey={englishText.PRICE_PER_METER}
														defaultMessage={englishText.PRICE_PER_METER}
													/>
												</h6>
											</>
										)}
									</div>
									<div className='object-items' style={{ overflow: 'hidden' }}>
										<h2 className='price'>
											{propertyDetail.formatedPrice || propertyDetail.totalRent
												? `${
														propertyDetail.formatedPrice ||
														`${formatCurrency(propertyDetail.totalRent)}`
												  }`
												: '-'}
										</h2>
										<h6>{isRent ? 'Mietpreis' : 'Kaufpreis'}</h6>
									</div>
								</div>
							</div>
							<div className='card-item'>
								<div className='d-flex align-items-start' style={{ placeContent: 'space-between' }}>
									<div className='object-items'>
										<h2>
											{propertyDetail.areaLiving
												? propertyDetail.areaLiving.toString().replace(',', '.') + ' m²'
												: propertyDetail.areaPlot
												? propertyDetail.areaPlot + ' m²'
												: '-'}
										</h2>
										<h6>
											<Trans i18nKey={englishText.SIZE} defaultMessage={englishText.SIZE} />
										</h6>
									</div>
									{propertyOption.land !== propertyDetail.objectType && (
										<>
											<div className='object-items'>
												<h2>
													{propertyDetail.propRoomsAmount
														? propertyDetail.propRoomsAmount
														: '-'}
												</h2>
												<h6>
													<Trans
														i18nKey={englishText.ROOMS_OP}
														defaultMessage={englishText.ROOMS_OP}
													/>
												</h6>
											</div>
											<div className='object-items'>
												<h2>{propertyDetail.yearConstructed || '-'}</h2>
												<h6>
													<Trans
														i18nKey={englishText.YEAR_OF_CONSTRUCTION}
														defaultMessage={englishText.YEAR_OF_CONSTRUCTION}
													/>
												</h6>
											</div>
										</>
									)}
								</div>
							</div>
							{(propertyDetail.costsCommissionPercentShow ||
								propertyDetail.costsCommissionShow ||
								propertyDetail.costsCommissionAmountShow) &&
								!isRent && (
									<div className='card-item'>
										<div className='flex align-items-center justify-content-between'>
											<div className='object-items'>
												<h2>
													{propertyDetail.costsCommissionPercentShow
														? `${propertyDetail.costsCommissionPercentShow}%`
														: propertyDetail.costsCommissionShow ||
														  propertyDetail.costsCommissionAmountShow ||
														  '-'}{' '}
													<span className='legal-text'>inkl. gesetzl. Mwst.</span>
												</h2>
												<h6>Provision für Käufer</h6>
											</div>

											{/* <div className='object-items'>
										<h2>{propertyDetail.costsCommissionAmount || '-'}</h2>

										<h6>Amount</h6>
									</div> */}
										</div>

										{/* <p className='mt-2 text-center font-weight-bold'>(inkl. gesetzl. Mwst.)</p> */}
									</div>
								)}

							{/* {user && (
								<div className='card-item'>
									<div className='flex align-items-center justify-content-between'>
										<div className='object-items'>
											<h2>{user.name || '-'} </h2>
											<h6>{isRealtor ? 'Firma' : 'Name'}</h6>
										</div>
										<div className='object-items'>
											<h2>{user.phone || '-'} </h2>
											<h6>Telefon/Mobil</h6>
										</div>
									</div>
								</div>
							)} */}

							{/* {token ? (
								profileDetail.data && propertyDetail.user_id !== profileDetail.data?.userId ? (
									<div className='card-item text--center '>
										{user && (
											<div className='flex align-items-center justify-content-between'>
												<div className='object-items'>
													<h2>{user.name || '-'} </h2>
													<h6>{isRealtor ? 'Firma' : 'Name'}</h6>
												</div>
												<div className='object-items'>
													<h2>{user.phone || '-'} </h2>
													<h6>Telefon/Mobil</h6>
												</div>
											</div>
										)}
										<button className='contact-btn' onClick={props.handelPopupContact}>
											Kontakt
										</button>
									</div>
								) : (
									<></>
								)
							) : ( */}
							<div className='card-item text--center '>
								{user && (
									<div className='object-items'>
										<h2 className='realtor-name'>{user.name || '-'} </h2>
										{/* <h6 className='realtor-name'>{isRealtor ? 'Firma' : 'Name'}</h6> */}
										<h6 className='realtor-name'>Telefon: {user.phone || '-'}</h6>
									</div>
								)}
								{(!token ||
									(token &&
										profileDetail.data &&
										propertyDetail.user_id !== profileDetail.data?.userId)) && (
									<button className='contact-btn' onClick={props.handelPopupContact}>
										Kontakt
									</button>
								)}
							</div>
							{/* )} */}
						</div>
					</div>
				</div>
			</div>
			<div className='row mt-5'>
				<div className='tab-view'>
					<Tabs defaultActiveKey='FACTS' id='uncontrolled-tab-example'>
						<Tab
							eventKey='FACTS'
							title={
								<p className='d-flex align-items-center'>
									<ObjectDataIcon className='object-data-svg' />
									{getLocalizeText(englishText.FACTS)}
								</p>
							}
						>
							<>
								{factsArray &&
									factsArray.length > 0 &&
									factsArray.map((fact: any, index: number) => (
										<div className='detail-item d-flex align-items-center' key={index}>
											<p className='w-50'>{getLocalizeText(fact.label)}</p>
											<p className='w-50 d-flex align-items-center'>
												<span
													className='color'
													style={{
														backgroundColor: fact.color
													}}
												></span>
												<span>{fact.value}</span>
											</p>
										</div>
									))}
							</>
						</Tab>

						<Tab
							eventKey='FINANCES'
							title={
								<p className='d-flex align-items-center'>
									<FinancesIcon className='object-data-svg' />
									{getLocalizeText(englishText.FINANCES)}
								</p>
							}
						>
							{financeArray &&
								financeArray.length > 0 &&
								financeArray.map((fact: any, index: number) => (
									<div className='detail-item d-flex align-items-center' key={index}>
										<p className='w-50'>{getLocalizeText(fact.label)}</p>
										<p className='w-50 d-flex align-items-center'>
											<span
												className='color'
												style={{
													backgroundColor: fact.color
												}}
											></span>
											<span>{fact.value}</span>
										</p>
									</div>
								))}
						</Tab>
						<Tab
							eventKey='DETAILS'
							title={
								<p className='d-flex align-items-center'>
									<DetailsIcon className='object-data-svg' />
									{getLocalizeText(englishText.DETAILS)}
								</p>
							}
						>
							{detailsArray &&
								detailsArray.length > 0 &&
								detailsArray.map((fact: any, index: number) => (
									<div className='detail-item d-flex align-items-center' key={index}>
										<p className='w-50'>{getLocalizeText(fact.label)}</p>
										<p className='w-50 d-flex align-items-center'>
											<span
												className='color'
												style={{
													backgroundColor: fact.color
												}}
											></span>
											<span>{getLocalizeText(fact.value)}</span>
										</p>
									</div>
								))}
						</Tab>
					</Tabs>
				</div>
				{/* <div className="col-lg-4 col-md-3 col-3">
          <div className="extra-section">
            <h2 className="object-score-title">Extras</h2>
            {propertyDetail.tags && propertyDetail.tags.length > 0 ? (
              propertyDetail.tags.map((tag: string, ind: number) => (
                <p key={ind}>{tag}</p>
              ))
            ) : (
              <h2>
                <p>{getLocalizeText(englishText.NO_EXTRA)}</p>
              </h2>
            )}
          </div>
        </div> */}
			</div>
		</>
	);
};

export default ObjectDescription;
