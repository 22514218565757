import { LinkIcon } from 'components/commonComponents/icons';
import { userRole } from 'components/profile/utils/constants';
import React, { FC } from 'react';

interface IProps {
	userDetail: {
		companyName: string;
		gender: string;
		firstName: string;
		lastName: string;
		postalCode: string;
		city: string;
		moreInformation: string;
		taxIdentifier: string;
		imprint: string;
		roleName: string;
		websiteUrl: string;
		contactEmail: string;
		contactNumber: string;
		privacyPolicy: string;
		street: string;
	};
}

const ObjectUserDetail: FC<IProps> = ({ userDetail }) => {
	const {
		companyName,
		gender,
		firstName,
		lastName,
		postalCode,
		city,
		moreInformation,
		taxIdentifier,
		imprint,
		roleName,
		websiteUrl,
		contactEmail,
		contactNumber,
		privacyPolicy,
		street
	} = userDetail;
	console.log('userDetail:', userDetail);

	return (
		<div className='object-user-detail-div'>
			{(companyName || websiteUrl) && (
				<div className='flex flex--column information-div'>
					{/* <h6>Firma / Unternehmensbezeichnung:</h6> */}
					{/* {websiteUrl.includes('https://') || websiteUrl.includes('http://') ? ( */}
					<a href={websiteUrl} target='_blank' rel='noreferrer'>
						<p className='font-weight-bold website-link'>
							{companyName || ''} <LinkIcon className='ml-1' width={'14px'} height='14px' />
						</p>
					</a>

					{/* <p dangerouslySetInnerHTML={{ __html: websiteUrl || '' }}></p> */}
				</div>
			)}
			<div className='information-div'>
				{/* <div className='flex align__items--center'>
					{gender && (
						<div className={'mr--5'}>
							<p>{getGender(gender)}</p>
						</div>
					)}
					{firstName && (
						<div className='mr--5'>
							<p>{firstName}</p>
						</div>
					)}
					{lastName && (
						<div className='mr--5'>
							<p>{lastName}</p>
						</div>
					)}
				</div> */}
				{/* {contactEmail && (
					<a className='font-size--15' href={`mailto:${contactEmail}`} target='_blank' rel='noreferrer'>
						{contactEmail}
					</a>
				)} */}
				<p>{street || ''}</p>
				<p>
					{postalCode || ''} {city || ''}
				</p>
				{/* {contactNumber && <p>{contactNumber}</p>} */}
			</div>

			{moreInformation && (
				<div className='information-div'>
					<div className='flex align__items--center'>
						<h6>Weitere Angaben:</h6>
						<p dangerouslySetInnerHTML={{ __html: moreInformation || '' }}></p>
					</div>
				</div>
			)}

			{roleName === userRole.professionalBroker && (
				<div className='information-div'>
					{taxIdentifier && (
						<div className='flex align__items--center'>
							<h6>Umsatzsteuer-ID:</h6>
							<p>{taxIdentifier || ''}</p>
						</div>
					)}
					{imprint && (
						<div>
							{imprint.includes('https://') || imprint.includes('http://') ? (
								<p className='imprint-link'>
									<a href={imprint} target='_blank' rel='noreferrer'>
										Impressum des Anbieters
									</a>
								</p>
							) : (
								<>
									<h6>Impressum des Anbieters:</h6>
									<div dangerouslySetInnerHTML={{ __html: imprint }}></div>
								</>
							)}
						</div>
					)}
					{privacyPolicy && (
						<div>
							{privacyPolicy.includes('https://') || privacyPolicy.includes('http://') ? (
								<p className='imprint-link'>
									<a href={privacyPolicy} target='_blank' rel='noreferrer'>
										Datenschutz
									</a>
								</p>
							) : (
								<>
									<h6>Datenschutz:</h6>
									<div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
								</>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ObjectUserDetail;
